<template>
  <div class="container">
    <form @submit.prevent="submit">
      <div class="input-container">
        <label>First Name: </label>
        <input v-model="form.first_name" type="text" />
      </div>
      <small v-if="errors.first_name">{{ errors.first_name[0].message }}</small>
      <div class="input-container">
        <label>Last Name: </label>
        <input v-model="form.last_name" type="text" />
      </div>
      <small v-if="errors.last_name">{{ errors.last_name[0].message }}</small>
      <div class="input-container">
        <label>Phone: </label>
        <input v-model="form.phone" type="text" />
      </div>
      <small v-if="errors.phone">{{ errors.phone[0].message }}</small>
      <div class="input-container">
        <label>Email: </label>
        <input v-model="form.email" type="text" />
      </div>
      <small v-if="errors.email">{{ errors.email[0].message }}</small>
      <div class="input-container">
        <label>Password: </label>
        <input v-model="form.password" type="text" />
      </div>
      <small v-if="errors.password">{{ errors.password[0].message }}</small>
      <button type="submit">Register</button>
    </form>
  </div>
</template>
<script>
import FormMixin from "../mixins/FormMixin";
export default {
  name: "NewAdmin",
  mixins: [FormMixin],
  created() {
    this.setForm({
      first_name: ["required"],
      last_name: ["required"],
      phone: ["required"],
      email: ["required"],
      password: ["required"],
    });
  },
  methods: {
    async submit() {
      await this.validate();
      console.log(this.formValid);
      console.log(this.errors);
      if (this.formValid) {
        await this.$store.dispatch("admins/setUser", this.form);
        this.clearForm();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .input-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 6px;
      margin-bottom: 6px;
      label {
        margin-inline-end: 10px;
      }
    }
  }
}
</style>
