<template>
  <div class="addNewAdmin">
    <NewAdmin />
  </div>
</template>
<script>
import NewAdmin from "../components/NewAdmin.vue";
export default {
  name: "AddNewAdmin",
  components: {
    NewAdmin,
  },
  mounted() {
    console.log("Add User Mounted");
  },
};
</script>
<style lang="scss" scoped></style>
